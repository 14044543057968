<template>
  <div>
    <StatusFilterList
      v-bind="params"
      v-model="params.status"
      @change="change"
      :list="list"
    >
      <div class="text-right" slot="opt">
        <a-space :size="20">
          <gf-re-input-search
            @search="change({ name: $event, current: 1 })"
            style="width: 240px"
          >
            <a-select
              slot="addonBefore"
              style="width: 110px"
              v-model="nameType"
            >
              <a-select-option :value="0">需求名称</a-select-option>
              <a-select-option :value="1">公司名称</a-select-option>
            </a-select>
          </gf-re-input-search>
        </a-space>
      </div>
      <ProCard
        slot="card"
        slot-scope="{ dataSource }"
        :dataSource="dataSource"
        @click.native="push(dataSource.id)"
      >
        <status-box :status="dataSource.status" slot="status">
          <span v-if="dataSource.status === 10">待审核</span>
          <span v-else-if="dataSource.status === 20">管理员审核中</span>
          <span v-else-if="dataSource.status === 30">审核成功</span>
          <span v-else-if="dataSource.status === 99">审核失败</span>
        </status-box>
      </ProCard>
    </StatusFilterList>
  </div>
</template>

<script>
import ProCard from "./components/proCard.vue";
import ApplyForm from "./components/applyForm.vue";

import { getExpertRequirementList } from "@/api/api/requirement";
export default {
  components: { ProCard, ApplyForm },
  data() {
    return {
      nameType: 0,
      params: {
        current: 1,
        limit: 5,
        total: 0,
        status: 0,
        name: "",
        filters: [
          { value: "unconfirmed", name: "待审核" },
          { value: "confirmed", name: "已审核" },
        ],
      },
      list: [],
    };
  },
  created() {
    this.change();
  },
  methods: {
    // 获取列表
    change(e) {
      const params = this.generateParams(e);
      this.getList(params);
    },
    // 合成筛选参数
    generateParams(e) {
      Object.assign(this.params, e);

      const { name, status, limit, current } = this.params;

      const temp = {
        filter: status ? status : undefined,
        limit,
        offset: limit * (current - 1),
      };

      if (this.nameType) {
        temp.company = name;
      } else {
        temp.name = name;
      }

      return temp;
    },
    // 查看详情
    push(id) {
      this.$router.push(`/requirement/expert-apply/${id}`);
      this.id = id;
    },
    // 获取列表接口
    getList(params) {
      getExpertRequirementList(params).then(({ count, rows }) => {
        this.list = rows;
        this.$set(this.params, "total", count);
        let current = this.params.current;
        if (rows.length === 0 && current !== 1) {
          console.log(current, "当前页面没有数据了");
        }
      });
    },
  },
};
</script>